Alpine.store('inventory', {

    products: [],
    blink: null,
    register_at: null,
    wid: null,

    reset() {
        this.products = [];
        this.blink = null;
        this.register_at = null;
        this.storage?.persist();
    },

    formData() {
        return {
            products: this.products,
            register_at: this.register_at,
        };
    },

    isEmpty() {
        return !!(this.products?.length)
    },

    get storage() {
        if (!localStorage) {
            return null;
        }

        let store = this;
        return {
            persist() {
                const data = {
                    products: store.products,
                    register_at: store.register_at,
                    wid: store.wid
                }
                localStorage.setItem('inventory_data', JSON.stringify(data))
            },
            data() {
                return JSON.parse(localStorage.getItem('inventory_data') || '{}');
            },
            restore(data) {
                if (!data) data = this.data();
                store.products = data.products || [];
                store.register_at = data.register_at;
            },
            reset() {
                localStorage.removeItem('inventory_data');
            }
        }
    },

    prod: function (prod) {
        const store = this;
        return {

            get add() {
                //check if product already exist
                let old = store.products.find(p => p.id == prod.id);
                if (old) old.qte = (old.qte || 1) + 1;

                else {
                    store.products.unshift({
                        id: prod.id,
                        stock: prod.stock,
                        realstock: 0,
                        purchaseprice: 0,
                        damagedstock: 0,
                        observation: "-----",
                        original: prod
                    });
                }
                store.storage?.persist();
                this.blink();
            },

            get remove() {
                store.products.splice(store.products.indexOf(prod), 1);
                store.storage?.persist();
                return prod;
            },

            blink() {
                store.blink = prod.id;
                setTimeout(() => store.blink = undefined, 500);
            },

            get away() {
                return  prod.stock - prod.realstock - prod.damagedstock;
            },
        }
    },

});

Alpine.data('inventory', function (initial = {}) {

    return {
        init() {
            const store = this.$store.inventory;
            store.register_at = initial.register_at;
            store.wid = initial.wid;

            const old = store.storage?.data();
            if (old.wid != initial.wid) {
                store.storage?.reset();
            } else {
                store.storage?.restore();
            }
        },

        inventory_reset() {
            this.$store.inventory.reset();
            this.$store.inventory.storage.reset();
        },

        inventory_submit() {
            const store = this.$store.inventory;
            this.$wire.call('save', store.formData());
        }
    }
});
