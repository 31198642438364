Alpine.store('purchaseorder', {

    products: [],
    blink: null,
    register_at: null,
    contact: null,
    purchaseorder_taxes: [],
    taxe_items: [],
    auth_id: null,
    shippingdata: null,
    billingdata: null,
    paiementmode: null,
    notes: null,
    todeliver_at: null,
    discount: null,

    reset() {
        this.products = [];
        this.blink = null;
        this.register_at = null;
        this.todeliver_at = null;
        this.contact = null;
        this.shippingdata = null;
        this.billingdata = null;
        this.paiementmode = null;
        this.notes = null;
        this.globaldiscount = null;
        this.discount = null;
        this.purchaseorder_taxes = [];
        this.storage?.persist();
    },

    formData() {
            return {
            products: this.products,
            register_at: this.register_at,
            todeliver_at: this.todeliver_at,
            contact: this.contact ? this.contact : null,
            purchaseorder_taxes: this.purchaseorder_taxes,
            contact:this.contact,
            shippingdata:this.shippingdata,
            billingdata:this.billingdata,
            paiementmode:this.paiementmode,
            notes:this.notes,
            globaldiscount:this.globaldiscount,

        };
    },


    isEmpty() {
        return !!(this.products?.length)
    },

    get storage() {
        if (!localStorage) {
            return null;
        }

        let store = this;
        return {
            persist() {
                const data = {
                    products: store.products,
                    register_at: store.register_at,
                    todeliver_at: store.todeliver_at,
                    contact: store.contact,
                    shippingdata: store.shippingdata,
                    billingdata: store.billingdata,
                    paiementmode: store.paiementmode,
                    notes: store.notes,
                    globaldiscount: store.globaldiscount,
                    purchaseorder_taxes: store.purchaseorder_taxes,
                    auth_id: store.auth_id
                }
                localStorage.setItem('purchaseorder_data', JSON.stringify(data))
            },
            data() {
                return JSON.parse(localStorage.getItem('purchaseorder_data') || '{}');
            },
            restore(data) {
                if (!data) data = this.data();
                store.products = data.products || [];
                store.register_at = data.register_at;
                store.todeliver_at = data.todeliver_at;
                store.contact = data.contact;
                store.shippingdata = data.shippingdata;
                store.billingdata = data.billingdata;
                store.paiementmode = data.paiementmode;
                store.notes = data.notes;
                store.globaldiscount = data.globaldiscount;
                store.purchaseorder_taxes = data.purchaseorder_taxes || [];

            },
            reset() {
                localStorage.removeItem('purchaseorder_data');
            }
        }
    },

    prod: function (prod) {
        const store = this;
        return {

            get add() {
                //check if product already exist
                let old = store.products.find(p => p.id == prod.id);
                if (old) old.qte = (old.qte || 1) + 1;

                else {
                    store.products.unshift({
                        id: prod.id,
                        qte: 1,
                        price: prod.last_purchase_price,
                        discount: prod.discount,
                        original: prod
                    });
                }
                store.storage?.persist();
                this.blink();
            },

            get remove() {
                store.products.splice(store.products.indexOf(prod), 1);
                store.storage?.persist();
                return prod;
            },

            blink() {
                store.blink = prod.id;
                setTimeout(() => store.blink = undefined, 500);
            },

            get txo() {
                return store.taxe_items?.find(t => t.code == prod?.original.taxgroup && t.type == "P");
            },

            get ttc() {
                return prod.price * prod.qte;
            },

            get ht() {
                let txo = this.txo;
                if (!txo?.content) return this.ttc;
                return this.ttc * 100 / (100 + txo.content)
            },

            get taxe() {
                return this.ttc - this.ht;
            },

            get discount() {
                if (prod.discount) return this.ht * prod.discount / 100;
                return 0;
            },

            get real_ht() {
                if (!this.discount) return this.ht;
                return this.ht - this.discount;
            },

            get real_taxe() {
                if (!this.discount) return this.taxe;
                let txo = this.txo;
                if (txo?.content) return this.real_ht * txo.content / 100;
                return 0;
            },

            get real_ttc() {
                if (!this.discount) return this.ttc;
                return this.real_ht + this.real_taxe;
            },


        }
    },

    prods: function () {
        let store = this;
        return {
            get ttc() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).ttc);
                return a;
            },

            get real_ttc() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_ttc);
                return a;
            },

            get ht() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).ht);
                return a;
            },

            get real_ht() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_ht);
                return a;
            },

            get discount() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).discount);
                return a;
            },

            get gl_discount() {
                if (!store.globaldiscount) return 0;
                return this.ttc * (store.globaldiscount / 100);
            },
        }
    },

    taxes: function () {

        let store = this;

        return {
            toggle(taxe) {
                var i = store.purchaseorder_taxes.indexOf(taxe);
                if (i === -1)
                    store.purchaseorder_taxes?.push(taxe);
                else
                    store.purchaseorder_taxes?.splice(i, 1);
            },

            has(id) {
                return !!store.purchaseorder_taxes.find(el => `${el}` === `${id}`);
            },

            invoice(id = null) {

                if (id) {
                    if (!this.has(id)) return 0;
                    const t = store.taxe_items.find(el => el.id == id);
                    if (!t) return 0;
                    return store.prods().real_ht * t.content / 100;
                } else {
                    let a = 0;
                    store.purchaseorder_taxes.forEach(t => {
                        a += this.invoice(t) || 0;
                    })
                    return a;
                }
            },

            get prods() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_taxe);
                return a;
            }

        }
    },



    get net() {
        return (this.prods().real_ttc - this.prods().gl_discount ) + this.taxes().invoice();
    },

     get pttc() {
        return this.prods().real_ttc  + this.taxes().invoice();
    },


});

Alpine.data('purchaseorder', function(initial = {}) {

    return {
        init() {
            const store = this.$store.purchaseorder;
            store.taxe_items = initial.taxe_items;
            store.pay_items = initial.pay_items;
            store.register_at = initial.register_at;
            store.todeliver_at = initial.todeliver_at;
            store.auth_id = initial.auth_id;

            const old = store.storage?.data();
            if (old.auth_id != initial.auth_id) {
                store.storage?.reset();
            }else {
                store.storage?.restore();
            }

        },

        purchaseorder_reset() {
            this.$store.purchaseorder.reset();
            this.$store.purchaseorder.storage.reset();
            $("#pos-s2-contact").val(null).trigger('change');
        },

        purchaseorder_submit() {
            const store = this.$store.purchaseorder;
            this.$wire.call('save_purchaseorder', store.formData());
        }
    }
});
