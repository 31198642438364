Alpine.store('delivery', {

    products: [],
    blink: null,
    register_at: null,
    purchase: null,
    contact: null,
    sid: null,
    note: null,
    motif: null,
    store_id: null,

    reset() {
        this.products = [];
        this.blink = null;
        this.register_at = null;
        this.purchase = null;
        this.contact = null;
        this.note = null;
        this.motif = null;
        this.store_id = null;
        this.storage?.persist();
    },

    formData() {
        return {
            products: this.products,
            register_at: this.register_at,
            purchase_orders_id: this.purchase ? this.purchase : null,
            contact_id: this.contact ? this.contact : null,
            store_id: this.store_id,
            note: this.note,
            motif: this.motif,
        };
    },

    isEmpty() {
        return !!(this.products?.length)
    },

    get storage() {
        if (!localStorage) {
            return null;
        }

        let store = this;
        return {
            persist() {
                const data = {
                    products: store.products,
                    register_at: store.register_at,
                    purchase: store.purchase,
                    contact: store.contact,
                    note: store.note,
                    motif: store.motif,
                    sid: store.sid,
                    store_id: store.store_id,
                }
                localStorage.setItem('delivery_data', JSON.stringify(data))
            },
            data() {
                return JSON.parse(localStorage.getItem('delivery_data') || '{}');
            },
            restore(data) {
                if (!data) data = this.data();
                store.products = data.products || [];
                store.register_at = data.register_at;
                store.purchase = data.purchase;
                store.contact = data.contact;
                store.note = data.note;
                store.motif = data.motif;
                store.store_id = data.store_id;
            },
            reset() {
                localStorage.removeItem('delivery_data');
            }
        }
    },

    prod: function (prod) {
        const store = this;
        return {

            get add() {
                //check if product already exist
                let old = store.products.find(p => p.id == prod.id);
                if (old) old.qte = (old.qte || 1) + 1;

                else {
                    store.products.unshift({
                        id: prod.id,
                        qte: 1,
                        price: prod.price,
                        discount: prod.discount,
                        original: prod
                    });
                }
                store.storage?.persist();
                this.blink();
            },

            get remove() {
                store.products.splice(store.products.indexOf(prod), 1);
                store.storage?.persist();
                return prod;
            },

            blink() {
                store.blink = prod.id;
                setTimeout(() => store.blink = undefined, 500);
            },

            get txo() {
                return store.taxe_items?.find(t => t.code == prod?.original.taxgroup && t.type == "P");
            },

            get ttc() {
                return prod.price * prod.qte;
            },

            get ht() {
                let txo = this.txo;
                if (!txo?.content) return this.ttc;
                return this.ttc * 100 / (100 + txo.content)
            },

            get taxe() {
                return this.ttc - this.ht;
            },

            get discount() {
                if (prod.discount) return this.ht * prod.discount / 100;
                return 0;
            },

            get real_ht() {
                if (!this.discount) return this.ht;
                return this.ht - this.discount;
            },

            get real_taxe() {
                if (!this.discount) return this.taxe;
                let txo = this.txo;
                if (txo?.content) return this.real_ht * txo.content / 100;
                return 0;
            },

            get real_ttc() {
                if (!this.discount) return this.ttc;
                return this.real_ht + this.real_taxe;
            },
        }
    },

    prods: function () {
        let store = this;
        return {
            get ttc() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).ttc);
                return a;
            },

            get real_ttc() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_ttc);
                return a;
            },

            get ht() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).ht);
                return a;
            },

            get real_ht() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_ht);
                return a;
            },

            get discount() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).discount);
                return a;
            }
        }
    },

    taxes: function () {

        let store = this;

        return {
            toggle(taxe) {
                var i = store.invoice_taxes.indexOf(taxe);
                if (i === -1)
                    store.invoice_taxes?.push(taxe);
                else
                    store.invoice_taxes?.splice(i, 1);
            },

            has(id) {
                return !!store.invoice_taxes.find(el => `${el}` === `${id}`);
            },

            invoice(id = null) {

                if (id) {
                    if (!this.has(id)) return 0;
                    const t = store.taxe_items.find(el => el.id == id);
                    if (!t) return 0;
                    return store.prods().real_ht * t.content / 100;
                } else {
                    let a = 0;
                    store.invoice_taxes.forEach(t => {
                        a += this.invoice(t) || 0;
                    })
                    return a;
                }
            },

            get prods() {
                let a = 0;
                store.products.forEach(p => a += store.prod(p).real_taxe);
                return a;
            }

        }
    },

    get net() {
        return this.prods().real_ttc + this.taxes().invoice();
    },

});

Alpine.data('delivery', function (initial = {}) {

    return {
        init() {
            const store = this.$store.delivery;
            store.register_at = initial.register_at;
            store.sid = initial.sid;
            
            const old = store.storage?.data();

            if (old.sid != initial.sid) {
                store.storage?.reset();
            } else {
                store.storage?.restore();
            }
        },

        delivery_reset() {
            this.$store.delivery.reset();
            this.$store.delivery.storage.reset();
            $("#pos-s2-contact").val(null).trigger('change');
        },

        delivery_submit() {
            const store = this.$store.delivery;
            this.$wire.call('save_delivery', store.formData());
        }
    }
});
