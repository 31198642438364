Alpine.store('pos', {

    products: [],
    blink: null,
    quotation: false,
    normalize: false,
    register_at: null,
    contact: null,
    invoice_taxes: [],
    taxe_items: [],
    pay_items: [],
    pay_with: null,
    taib: null,
    note: null,
    bl_code: null,
    wid: null,
    received: undefined,

    reset() {
        this.products = [];
        this.blink = null;
        this.quotation = false;
        this.normalize = false;
        this.register_at = null;
        this.contact = null;
        this.invoice_taxes = [];
        this.pay_with = 'cash';
        this.taib = null;
        this.note = null;
        this.bl_code = null;
        this.storage?.persist();
        this.received = undefined;
    },

    formData() {
        const pay_items = this.pay_items || [];
        const pay_with = this.pay_with || (pay_items[0]?.code);

        return {
            products: this.products,
            quotation: this.quotation,
            normalize: this.normalize,
            register_at: this.register_at,
            contact_id: this.contact ? this.contact : null,
            invoice_taxes: this.invoice_taxes,
            contact: this.contact,
            taib: this.taib,
            note: this.note,
            bl_code: this.bl_code,
            pay_with,
            received: this.getReceived()
        };
    },

    setReceived(evt) {
        this.received = rwsp(evt.target.value);
    },

    getReceived() {
        if (this.received !== undefined) return this.received;
        return this.net;
    },

    get du() {
        let r = this.getReceived();
        let net = this.net;
        if (isNaN(net) || isNaN(r) || !r || !net) return null;
        return net - r;
    },

    isEmpty() {
        return !!(this.products?.length)
    },

    get storage() {
        if (!localStorage) {
            return null;
        }

        let store = this;
        return {
            persist() {
                const data = {
                    products: store.products,
                    quotation: store.quotation,
                    normalize: store.normalize,
                    register_at: store.register_at,
                    contact: store.contact,
                    invoice_taxes: store.invoice_taxes,
                    pay_with: store.pay_with,
                    taib: store.taib,
                    note: store.note,
                    bl_code: store.bl_code,
                    wid: store.wid
                }
                localStorage.setItem('pos_data', JSON.stringify(data))
            },
            data() {
                return JSON.parse(localStorage.getItem('pos_data') || '{}');
            },
            restore(data) {
                if (!data) data = this.data();
                store.products = data.products || [];
                store.quotation = data.quotation ? true : false;
                store.normalize = data.normalize ? true : false;
                store.register_at = data.register_at;
                store.contact = data.contact;
                store.invoice_taxes = data.invoice_taxes || [];
                store.pay_with = data.pay_with;
                store.taib = data.taib;
                store.note = data.note;
                store.bl_code = data.bl_code;
            },
            reset() {
                localStorage.removeItem('pos_data');
            }
        }
    },



    prod: function (prod) {
        const store = this;
        return {

            get add() {
                //check if product already exist
                let old = store.products.find(p => p.id == prod.id);
                if (old) old.qte = (old.qte || 1) + 1;

                else {

                    store.products.unshift({
                        id: prod.id,
                        qte: 1,
                        price: prod.price,
                        discount: prod.discount,
                        original: prod
                    });
                }
                store.storage?.persist();
                this.blink();
            },

            get remove() {
                store.products.splice(store.products.indexOf(prod), 1);
                store.storage?.persist();
                return prod;
            },

            blink() {
                store.blink = prod.id;
                setTimeout(() => store.blink = undefined, 500);
            },

            get updatePriceWithDiscount(){
                if(prod.discount)
                   prod.price = prod.original.price - prod.original.price * ( prod.discount / 100 );
               else
                prod.price = prod.original.price ;
        },

        get ttc() {
            return prod.price * prod.qte;
        },

        get orginal_ttc() {
            return prod.original.price * prod.qte;
        },



        get discount() {
            if (prod.discount) return this.orginal_ttc * prod.discount / 100;
            return 0;
        },

    }
},

prods: function () {
    let store = this;
    return {

        get ttc() {
            let a = 0;
            store.products.forEach(p => a += store.prod(p).ttc);
            return a;
        },

        get discount() {
            let a = 0;
            store.products.forEach(p => a += store.prod(p).discount);
            return a;
        }
    }
},


get net() {
    return this.prods().ttc ;
},

});

Alpine.data('pos', function (initial = {}) {

    return {
        init() {
            const store = this.$store.pos;
            store.taxe_items = initial.taxe_items;
            store.pay_items = initial.pay_items;
            store.register_at = initial.register_at;
            store.wid = initial.wid;

            const old = store.storage?.data();
            if (old.wid != initial.wid) {
                store.storage?.reset();
            } else {
                store.storage?.restore();
            }
        },

        pos_reset() {
            this.$store.pos.reset();
            this.$store.pos.storage.reset();
            $("#pos-s2-contact").val(null).trigger('change');
        },

        pos_submit() {
            const store = this.$store.pos;
            this.$wire.call('save', store.formData());
        }
    }
});
