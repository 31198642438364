import Alpine from 'alpinejs';
import jQuery from "jquery";
import "./dashmix/vendor/select2/dist/js/select2";
window.Alpine = Alpine;

window.$ = jQuery;
window.jQuery = jQuery;

document.addEventListener('alpine:init', function (evt) {

    /* Toggle class */
    Alpine.data('tc', function (cn = '') {

        cn = (cn || '').replace(/\s+/g, ' ');

        return {
            tc_c: cn,
            tc_pass: false,

            init() {
                this.$watch('tc_c', () => {
                    this.tc_pass = true;
                });
            },

            tc_on: function () {
                this.tc_c = cn;
            },

            tc_off: function () {
                this.tc_c = '';
            },
            tc_toggle: function () {
                this.tc_c = this.tc_c?.length ? '' : cn;
            }
        }
    });

    /* Switch between elements */
    Alpine.data('sw', function (el1, el2) {

        el1 = (el1 || '').trim();
        el2 = (el2 || '').trim();

        return {
            sw_c: el1,

            sw_set: function (i = 1) {
                this.sw_c = (i > 1) ? el2 : el1;
            },

            sw_toggle: function () {
                this.sw_c = (this.sw_c == el1) ? el2 : el1;
            },

            sw_is: function (el) {
                return this.sw_c === el;
            }
        }
    });

    /* Hide validation notice  */
    Alpine.data('hvn', function (el) {
        return {
            hvn_hide: function (c = 'is-invalid') {
                if (!el) return;
                el.classList.remove(c);
                var ms = el.querySelector('.message')?.remove();
            }
        }
    });

    /* Namespace Queue elements */
    Alpine.data('NQ', function (init = {}) {

        return {
            data: init,

            //remove
            nq_r: function (namespace, key) {
                var q = (this.data || {})[namespace];
                if (!q) return true;
                q = ((this.data || {})[namespace])[key]
                if (!q) return true;

                var d = this.data;
                delete d[namespace][key];
                this.data = d;
                return true;
            },

            //put
            nq_p: function (namespace, key, value = true) {

                var d = (this.data || {});
                d[namespace] = d[namespace] || {};
                d[namespace][key] = value;
                this.data = d;
            },

            //current
            nq_c: function (namespace, key) {
                return ((this.data || {})[namespace] || {})[key];
            }
        }
    });

    /* POS  */
    require('./alpine.init.pos');
    require('./alpine.init.posService');
    require('./alpine.init.stockout');
    require('./alpine.init.inventory');
    require('./alpine.init.purchase');
    require('./alpine.init.purchaseorder');
    require('./alpine.init.adjustment');
    require('./alpine.init.transfert');
    require('./alpine.init.delivery');

    Alpine.store('invStore', {
        products: [],

        add(prod) {
            this.products.unshift(prod);
        },

        remove(p) {

        }
    }),

        Alpine.data('invoice', function (data) {
            return {
                submit: function () {
                    this.$wire.set('data', this.$store.invStore.products, true);
                    this.$wire.call('save');
                },
            }
        });

    

    Alpine.data('copier', function () {

        return {

            copy: function (evt, target) {
                try {
                    target.select();
                    target.setSelectionRange(0, 99999); /* For mobile devices */
                    navigator.clipboard.writeText(target.value);
                    flipTooltip(evt.target, "Copié !");
                } catch (error) {
                    flipTooltip(evt.target, "Non supporté");
                }

            }
        }
    })

    Alpine.data('kkiapay', function (pub_k) {
         console.log(addSuccessListener);
        return {
            checking: false,
            init() {
                addSuccessListener(this.$wire.paid);
            },
            pay: function (data) {

                if (data.data) {
                    let div = document.createElement('div');
                    div.innerHTML = data.data;
                    data.data = JSON.parse(div.innerText);
                }

                let widget = openKkiapayWidget({
                    ...data,
                    theme: "#f4623a",
                    key: pub_k
                });
            }
        }
    });

    Alpine.data('s2', function (options = {}) {
        return {
            s2o: null,
            init() {
                this.s2o = $(this.$root).select2({
                    width: '100%',
                    ...options,
                    events: undefined
                });

                for (const key in (options || {})) {
                    if (`${key}`.startsWith('on:') && Object.hasOwnProperty.call(options, key)) {
                        this.s2o.on(key.substring(3), evt => options[key](evt, this));
                    }
                }
            }
        }
    });
});


function flipTooltip(el, text, time = 2000) {
    if (!el || !text) return;
    var tooltip = bootstrap.Tooltip.getOrCreateInstance(el);
    var c_text = el.getAttribute('data-bs-original-title') || el.getAttribute('title');
    el?.setAttribute('data-bs-original-title', text);
    tooltip.show();
    setTimeout(() => {
        el?.setAttribute('data-bs-original-title', c_text);
        tooltip.hide();
    }, time);
}

Alpine.start();
